import React from "react";
import Layout from "components/Layout";
import { LayoutSinglePage } from "components";

const ComingSoonPage = ({ location }) => {
  return (
    <Layout
      location={location}
      pageTitle="Coming soon - Reprocess & Repurpose"
      theme="light"
    >
      <LayoutSinglePage>
        <h1 className="w-full text-center">Coming soon...</h1>
      </LayoutSinglePage>
    </Layout>
  );
};

export default ComingSoonPage;
